import { useState, useEffect } from 'react';
import './FinalReport.scss';

import { connect } from 'react-redux';

import { closeSession } from '../../redux/master/action';
import { logout } from '../../redux/auth/action';


function FinalReport(props) {

    useEffect(() => {
        document.title = "Финальный отчет сессии | TOTO";
    }, [])

    const [tip, setTip] = useState('')

    const onChangeTip = (event) => {
        setTip(event.target.value)
    }

    const closeSession = (event) => {
        event.preventDefault();

        if (tip !== '') {
            props.closeSession(tip)
            .then(() => {
                props.logout()
            })
        }
    }


    return (
        <div id="final_report">

            <h1>Выполнено за смену: {props.finalReport.totalReport.made_orders} шт. услуг</h1>
            <div className="table_wrapp">
                <table>
                    <thead>
                        <tr>
                            <th>Наименование услуги</th>
                            <th>Выполнено шт.</th>
                            <th>Общая сумма</th>
                            <th>Ваш доход</th>
                        </tr>
                    </thead>
                    <tbody>

                        {props.finalReport.reportData.map(value => {
                            return (
                                <tr>
                                    <td>{value.service_name}</td>
                                    <td>{value.count}</td>
                                    <td>{value.sum}</td>
                                    <td>{value.master_profit}</td>
                                </tr>
                            )
                        })}

                        <tr style={{ background: 'rgb(255 0 0 / 0.3)', fontWeight: 'bold', fontSize: '20px' }}>
                            <td>ИТОГО:</td>
                            <td>{props.finalReport.totalReport.made_orders} шт.</td>
                            <td>{props.finalReport.totalReport.total_money} р.</td>
                            <td>{props.finalReport.totalReport.master_income} р.</td>

                        </tr>

                    </tbody>
                </table>
            </div>

            <div class='profit_block'>
                <table>
                    <caption>Общая выручка</caption>
                    <tbody>
                        {props.finalReport.sumPaymentTypes.map(obj => {
                            return (
                                <tr>
                                    <td>{obj.paymentTypeName}</td>
                                    <td style={{ color: '#b30101' }}>{obj.totalSum} p.</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>

            <div class='profit_block'>
                <table>
                    <tbody>
                        <tr>
                            <td>Ваш доход за сегодня:</td>
                            <td style={{ color: '#b30101' }}>{props.finalReport.totalReport.master_income} p.</td>
                        </tr>
                        <tr>
                            <td>Оплата по договору: {props.finalReport.totalReport.total_money} - {props.finalReport.totalReport.master_income} - 
                             {' '}   {props.finalReport.sumPaymentTypes[1].totalSum}
                            :</td>
                            <td style={{ color: '#b30101' }}>{(props.finalReport.totalReport.work_point_income - props.finalReport.sumPaymentTypes[1].totalSum).toFixed(2)} p.</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <form onSubmit={closeSession}>
                <legend>Введите сумму чаевых:</legend>
                <input onChange={onChangeTip} value={tip} type="number" placeholder="чаевые" required />
                <button type='submit' >Завершить</button>
            </form>

        </div>
    );
}


const mapStateToProps = state => {
    return {
        finalReport: state.masterReducer.finalReport
    }
}

const mapDispatchToProps = {
    closeSession,
    logout
}


export default connect(mapStateToProps, mapDispatchToProps)(FinalReport)